export const prepareComponentToken = (token) => {
    const { fontSize, lineHeight, controlHeight, controlPaddingHorizontal, zIndexPopupBase, colorText, fontWeightStrong, controlItemBgActive, controlItemBgHover, colorBgContainer, colorFillSecondary, controlHeightLG, controlHeightSM, colorBgContainerDisabled, colorTextDisabled, } = token;
    return {
        zIndexPopup: zIndexPopupBase + 50,
        optionSelectedColor: colorText,
        optionSelectedFontWeight: fontWeightStrong,
        optionSelectedBg: controlItemBgActive,
        optionActiveBg: controlItemBgHover,
        optionPadding: `${(controlHeight - fontSize * lineHeight) / 2}px ${controlPaddingHorizontal}px`,
        optionFontSize: fontSize,
        optionLineHeight: lineHeight,
        optionHeight: controlHeight,
        selectorBg: colorBgContainer,
        clearBg: colorBgContainer,
        singleItemHeightLG: controlHeightLG,
        multipleItemBg: colorFillSecondary,
        multipleItemBorderColor: 'transparent',
        multipleItemHeight: controlHeightSM,
        multipleItemHeightLG: controlHeight,
        multipleSelectorBgDisabled: colorBgContainerDisabled,
        multipleItemColorDisabled: colorTextDisabled,
        multipleItemBorderColorDisabled: 'transparent',
        showArrowPaddingInlineEnd: Math.ceil(token.fontSize * 1.25),
    };
};
