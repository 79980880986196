import useMemo from 'rc-util/lib/hooks/useMemo';
import isEqual from 'rc-util/lib/isEqual';
import { defaultConfig } from '../../theme/internal';
import useThemeKey from './useThemeKey';
import { devUseWarning } from '../../_util/warning';
export default function useTheme(theme, parentTheme) {
    var _a, _b;
    const warning = devUseWarning('ConfigProvider');
    const themeConfig = theme || {};
    const parentThemeConfig = themeConfig.inherit === false || !parentTheme
        ? Object.assign(Object.assign({}, defaultConfig), { hashed: (_a = parentTheme === null || parentTheme === void 0 ? void 0 : parentTheme.hashed) !== null && _a !== void 0 ? _a : defaultConfig.hashed, cssVar: parentTheme === null || parentTheme === void 0 ? void 0 : parentTheme.cssVar }) : parentTheme;
    const themeKey = useThemeKey();
    if (process.env.NODE_ENV !== 'production') {
        const cssVarEnabled = themeConfig.cssVar || parentThemeConfig.cssVar;
        const validKey = !!((typeof themeConfig.cssVar === 'object' && ((_b = themeConfig.cssVar) === null || _b === void 0 ? void 0 : _b.key)) ||
            themeKey);
        warning(!cssVarEnabled || validKey, 'breaking', 'Missing key in `cssVar` config. Please upgrade to React 18 or set `cssVar.key` manually in each ConfigProvider inside `cssVar` enabled ConfigProvider.');
    }
    return useMemo(() => {
        var _a, _b;
        if (!theme) {
            return parentTheme;
        }
        // Override
        const mergedComponents = Object.assign({}, parentThemeConfig.components);
        Object.keys(theme.components || {}).forEach((componentName) => {
            mergedComponents[componentName] = Object.assign(Object.assign({}, mergedComponents[componentName]), theme.components[componentName]);
        });
        const cssVarKey = `css-var-${themeKey.replace(/:/g, '')}`;
        const mergedCssVar = ((_a = themeConfig.cssVar) !== null && _a !== void 0 ? _a : parentThemeConfig.cssVar) && Object.assign(Object.assign(Object.assign({ prefix: 'ant' }, (typeof parentThemeConfig.cssVar === 'object' ? parentThemeConfig.cssVar : {})), (typeof themeConfig.cssVar === 'object' ? themeConfig.cssVar : {})), { key: (typeof themeConfig.cssVar === 'object' && ((_b = themeConfig.cssVar) === null || _b === void 0 ? void 0 : _b.key)) || cssVarKey });
        // Base token
        return Object.assign(Object.assign(Object.assign({}, parentThemeConfig), themeConfig), { token: Object.assign(Object.assign({}, parentThemeConfig.token), themeConfig.token), components: mergedComponents, cssVar: mergedCssVar });
    }, [themeConfig, parentThemeConfig], (prev, next) => prev.some((prevTheme, index) => {
        const nextTheme = next[index];
        return !isEqual(prevTheme, nextTheme, true);
    }));
}
