var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { useRef, useState } from 'react';
import EyeInvisibleOutlined from '@ant-design/icons/EyeInvisibleOutlined';
import EyeOutlined from '@ant-design/icons/EyeOutlined';
import classNames from 'classnames';
import omit from 'rc-util/lib/omit';
import { composeRef } from 'rc-util/lib/ref';
import { ConfigContext } from '../config-provider';
import useRemovePasswordTimeout from './hooks/useRemovePasswordTimeout';
import Input from './Input';
const defaultIconRender = (visible) => visible ? React.createElement(EyeOutlined, null) : React.createElement(EyeInvisibleOutlined, null);
const actionMap = {
    click: 'onClick',
    hover: 'onMouseOver',
};
const Password = React.forwardRef((props, ref) => {
    const { visibilityToggle = true } = props;
    const visibilityControlled = typeof visibilityToggle === 'object' && visibilityToggle.visible !== undefined;
    const [visible, setVisible] = useState(() => visibilityControlled ? visibilityToggle.visible : false);
    const inputRef = useRef(null);
    React.useEffect(() => {
        if (visibilityControlled) {
            setVisible(visibilityToggle.visible);
        }
    }, [visibilityControlled, visibilityToggle]);
    // Remove Password value
    const removePasswordTimeout = useRemovePasswordTimeout(inputRef);
    const onVisibleChange = () => {
        const { disabled } = props;
        if (disabled) {
            return;
        }
        if (visible) {
            removePasswordTimeout();
        }
        setVisible((prevState) => {
            var _a;
            const newState = !prevState;
            if (typeof visibilityToggle === 'object') {
                (_a = visibilityToggle.onVisibleChange) === null || _a === void 0 ? void 0 : _a.call(visibilityToggle, newState);
            }
            return newState;
        });
    };
    const getIcon = (prefixCls) => {
        const { action = 'click', iconRender = defaultIconRender } = props;
        const iconTrigger = actionMap[action] || '';
        const icon = iconRender(visible);
        const iconProps = {
            [iconTrigger]: onVisibleChange,
            className: `${prefixCls}-icon`,
            key: 'passwordIcon',
            onMouseDown: (e) => {
                // Prevent focused state lost
                // https://github.com/ant-design/ant-design/issues/15173
                e.preventDefault();
            },
            onMouseUp: (e) => {
                // Prevent caret position change
                // https://github.com/ant-design/ant-design/issues/23524
                e.preventDefault();
            },
        };
        return React.cloneElement(React.isValidElement(icon) ? icon : React.createElement("span", null, icon), iconProps);
    };
    const { className, prefixCls: customizePrefixCls, inputPrefixCls: customizeInputPrefixCls, size } = props, restProps = __rest(props, ["className", "prefixCls", "inputPrefixCls", "size"]);
    const { getPrefixCls } = React.useContext(ConfigContext);
    const inputPrefixCls = getPrefixCls('input', customizeInputPrefixCls);
    const prefixCls = getPrefixCls('input-password', customizePrefixCls);
    const suffixIcon = visibilityToggle && getIcon(prefixCls);
    const inputClassName = classNames(prefixCls, className, {
        [`${prefixCls}-${size}`]: !!size,
    });
    const omittedProps = Object.assign(Object.assign({}, omit(restProps, ['suffix', 'iconRender', 'visibilityToggle'])), { type: visible ? 'text' : 'password', className: inputClassName, prefixCls: inputPrefixCls, suffix: suffixIcon });
    if (size) {
        omittedProps.size = size;
    }
    return React.createElement(Input, Object.assign({ ref: composeRef(ref, inputRef) }, omittedProps));
});
if (process.env.NODE_ENV !== 'production') {
    Password.displayName = 'Input.Password';
}
export default Password;
