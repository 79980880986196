var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import EllipsisOutlined from '@ant-design/icons/EllipsisOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import classNames from 'classnames';
import RcTabs from 'rc-tabs';
import { devUseWarning } from '../_util/warning';
import { ConfigContext } from '../config-provider';
import useCSSVarCls from '../config-provider/hooks/useCSSVarCls';
import useSize from '../config-provider/hooks/useSize';
import useAnimateConfig from './hooks/useAnimateConfig';
import useLegacyItems from './hooks/useLegacyItems';
import useStyle from './style';
import TabPane from './TabPane';
const Tabs = (props) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { type, className, rootClassName, size: customSize, onEdit, hideAdd, centered, addIcon, moreIcon, popupClassName, children, items, animated, style, indicatorSize, indicator } = props, otherProps = __rest(props, ["type", "className", "rootClassName", "size", "onEdit", "hideAdd", "centered", "addIcon", "moreIcon", "popupClassName", "children", "items", "animated", "style", "indicatorSize", "indicator"]);
    const { prefixCls: customizePrefixCls } = otherProps;
    const { direction, tabs, getPrefixCls, getPopupContainer } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('tabs', customizePrefixCls);
    const rootCls = useCSSVarCls(prefixCls);
    const [wrapCSSVar, hashId, cssVarCls] = useStyle(prefixCls, rootCls);
    let editable;
    if (type === 'editable-card') {
        editable = {
            onEdit: (editType, { key, event }) => {
                onEdit === null || onEdit === void 0 ? void 0 : onEdit(editType === 'add' ? event : key, editType);
            },
            removeIcon: React.createElement(CloseOutlined, null),
            addIcon: (addIcon !== null && addIcon !== void 0 ? addIcon : tabs === null || tabs === void 0 ? void 0 : tabs.addIcon) || React.createElement(PlusOutlined, null),
            showAdd: hideAdd !== true,
        };
    }
    const rootPrefixCls = getPrefixCls();
    if (process.env.NODE_ENV !== 'production') {
        const warning = devUseWarning('Tabs');
        warning(!('onPrevClick' in props) && !('onNextClick' in props), 'breaking', '`onPrevClick` and `onNextClick` has been removed. Please use `onTabScroll` instead.');
        warning(!(indicatorSize || (tabs === null || tabs === void 0 ? void 0 : tabs.indicatorSize)), 'deprecated', '`indicatorSize` has been deprecated. Please use `indicator={{ size: ... }}` instead.');
    }
    const size = useSize(customSize);
    const mergedItems = useLegacyItems(items, children);
    const mergedAnimated = useAnimateConfig(prefixCls, animated);
    const mergedStyle = Object.assign(Object.assign({}, tabs === null || tabs === void 0 ? void 0 : tabs.style), style);
    const mergedIndicator = {
        align: (_a = indicator === null || indicator === void 0 ? void 0 : indicator.align) !== null && _a !== void 0 ? _a : (_b = tabs === null || tabs === void 0 ? void 0 : tabs.indicator) === null || _b === void 0 ? void 0 : _b.align,
        size: (_f = (_d = (_c = indicator === null || indicator === void 0 ? void 0 : indicator.size) !== null && _c !== void 0 ? _c : indicatorSize) !== null && _d !== void 0 ? _d : (_e = tabs === null || tabs === void 0 ? void 0 : tabs.indicator) === null || _e === void 0 ? void 0 : _e.size) !== null && _f !== void 0 ? _f : tabs === null || tabs === void 0 ? void 0 : tabs.indicatorSize,
    };
    return wrapCSSVar(React.createElement(RcTabs, Object.assign({ direction: direction, getPopupContainer: getPopupContainer, moreTransitionName: `${rootPrefixCls}-slide-up` }, otherProps, { items: mergedItems, className: classNames({
            [`${prefixCls}-${size}`]: size,
            [`${prefixCls}-card`]: ['card', 'editable-card'].includes(type),
            [`${prefixCls}-editable-card`]: type === 'editable-card',
            [`${prefixCls}-centered`]: centered,
        }, tabs === null || tabs === void 0 ? void 0 : tabs.className, className, rootClassName, hashId, cssVarCls, rootCls), popupClassName: classNames(popupClassName, hashId, cssVarCls, rootCls), style: mergedStyle, editable: editable, moreIcon: (_g = moreIcon !== null && moreIcon !== void 0 ? moreIcon : tabs === null || tabs === void 0 ? void 0 : tabs.moreIcon) !== null && _g !== void 0 ? _g : React.createElement(EllipsisOutlined, null), prefixCls: prefixCls, animated: mergedAnimated, indicator: mergedIndicator })));
};
Tabs.TabPane = TabPane;
if (process.env.NODE_ENV !== 'production') {
    Tabs.displayName = 'Tabs';
}
export default Tabs;
