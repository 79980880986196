import classNames from 'classnames';
import * as React from 'react';
function notEmpty(val) {
    return val !== undefined && val !== null;
}
const Cell = (props) => {
    const { itemPrefixCls, component, span, className, style, labelStyle, contentStyle, bordered, label, content, colon, type, } = props;
    const Component = component;
    if (bordered) {
        return (React.createElement(Component, { className: classNames({
                [`${itemPrefixCls}-item-label`]: type === 'label',
                [`${itemPrefixCls}-item-content`]: type === 'content',
            }, className), style: style, colSpan: span },
            notEmpty(label) && React.createElement("span", { style: labelStyle }, label),
            notEmpty(content) && React.createElement("span", { style: contentStyle }, content)));
    }
    return (React.createElement(Component, { className: classNames(`${itemPrefixCls}-item`, className), style: style, colSpan: span },
        React.createElement("div", { className: `${itemPrefixCls}-item-container` },
            (label || label === 0) && (React.createElement("span", { className: classNames(`${itemPrefixCls}-item-label`, {
                    [`${itemPrefixCls}-item-no-colon`]: !colon,
                }), style: labelStyle }, label)),
            (content || content === 0) && (React.createElement("span", { className: classNames(`${itemPrefixCls}-item-content`), style: contentStyle }, content)))));
};
export default Cell;
