import { genSelectionStyle } from '../../select/style/multiple';
import { mergeToken } from '../../theme/internal';
const genSize = (token, suffix) => {
    const { componentCls, selectHeight, fontHeight, lineWidth, calc } = token;
    const suffixCls = suffix ? `${componentCls}-${suffix}` : '';
    const height = token.calc(fontHeight).add(2).equal();
    const restHeight = () => calc(selectHeight).sub(height).sub(calc(lineWidth).mul(2));
    const paddingTop = token.max(restHeight().div(2).equal(), 0);
    const paddingBottom = token.max(restHeight().sub(paddingTop).equal(), 0);
    return [
        genSelectionStyle(token, suffix),
        {
            [`${componentCls}-multiple${suffixCls}`]: {
                paddingTop,
                paddingBottom,
                paddingInlineStart: paddingTop,
            },
        },
    ];
};
const genPickerMultipleStyle = (token) => {
    const { componentCls, calc, lineWidth } = token;
    const smallToken = mergeToken(token, {
        fontHeight: token.fontSize,
        selectHeight: token.controlHeightSM,
        multipleSelectItemHeight: token.controlHeightXS,
        borderRadius: token.borderRadiusSM,
        borderRadiusSM: token.borderRadiusXS,
    });
    const largeToken = mergeToken(token, {
        fontHeight: calc(token.multipleItemHeightLG)
            .sub(calc(lineWidth).mul(2).equal())
            .equal(),
        fontSize: token.fontSizeLG,
        selectHeight: token.controlHeightLG,
        multipleSelectItemHeight: token.multipleItemHeightLG,
        borderRadius: token.borderRadiusLG,
        borderRadiusSM: token.borderRadius,
    });
    return [
        // ======================== Size ========================
        genSize(smallToken, 'small'),
        genSize(token),
        genSize(largeToken, 'large'),
        // ====================== Selection ======================
        genSelectionStyle(token),
        {
            [`${componentCls}${componentCls}-multiple`]: {
                width: '100%',
                // ==================== Selector =====================
                [`${componentCls}-selector`]: {
                    flex: 'auto',
                    padding: 0,
                    '&:after': {
                        margin: 0,
                    },
                },
                // ==================== Selection ====================
                [`${componentCls}-selection-item`]: {
                    marginBlock: 0,
                },
                // ====================== Input ======================
                // Input is `readonly`, which is used for a11y only
                [`${componentCls}-multiple-input`]: {
                    width: 0,
                    height: 0,
                    border: 0,
                    visibility: 'hidden',
                    position: 'absolute',
                    zIndex: -1,
                },
            },
        },
    ];
};
export default genPickerMultipleStyle;
