const getMoveTranslate = (direction) => {
    const value = '100%';
    return {
        left: `translateX(-${value})`,
        right: `translateX(${value})`,
        top: `translateY(-${value})`,
        bottom: `translateY(${value})`,
    }[direction];
};
const getEnterLeaveStyle = (startStyle, endStyle) => ({
    '&-enter, &-appear': Object.assign(Object.assign({}, startStyle), { '&-active': endStyle }),
    '&-leave': Object.assign(Object.assign({}, endStyle), { '&-active': startStyle }),
});
const getFadeStyle = (from, duration) => (Object.assign({ '&-enter, &-appear, &-leave': {
        '&-start': {
            transition: 'none',
        },
        '&-active': {
            transition: `all ${duration}`,
        },
    } }, getEnterLeaveStyle({
    opacity: from,
}, {
    opacity: 1,
})));
const getPanelMotionStyles = (direction, duration) => [
    getFadeStyle(0.7, duration),
    getEnterLeaveStyle({
        transform: getMoveTranslate(direction),
    }, {
        transform: 'none',
    }),
];
const genMotionStyle = (token) => {
    const { componentCls, motionDurationSlow } = token;
    return {
        [componentCls]: {
            // ======================== Mask ========================
            [`${componentCls}-mask-motion`]: getFadeStyle(0, motionDurationSlow),
            // ======================= Panel ========================
            [`${componentCls}-panel-motion`]: ['left', 'right', 'top', 'bottom'].reduce((obj, direction) => (Object.assign(Object.assign({}, obj), { [`&-${direction}`]: getPanelMotionStyles(direction, motionDurationSlow) })), {}),
        },
    };
};
export default genMotionStyle;
