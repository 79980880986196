import { prepareComponentToken, prepareToken } from './token';
import { genCompactItemStyle } from '../../style/compact-item';
import { genCompactItemVerticalStyle } from '../../style/compact-item-vertical';
import { genSubStyleComponent } from '../../theme/internal';
import { unit } from '@ant-design/cssinjs';
const genButtonCompactStyle = (token) => {
    const { componentCls, calc } = token;
    return {
        [componentCls]: {
            // Special styles for Primary Button
            [`&-compact-item${componentCls}-primary`]: {
                [`&:not([disabled]) + ${componentCls}-compact-item${componentCls}-primary:not([disabled])`]: {
                    position: 'relative',
                    '&:before': {
                        position: 'absolute',
                        top: calc(token.lineWidth).mul(-1).equal(),
                        insetInlineStart: calc(token.lineWidth).mul(-1).equal(),
                        display: 'inline-block',
                        width: token.lineWidth,
                        height: `calc(100% + ${unit(token.lineWidth)} * 2)`,
                        backgroundColor: token.colorPrimaryHover,
                        content: '""',
                    },
                },
            },
            // Special styles for Primary Button
            '&-compact-vertical-item': {
                [`&${componentCls}-primary`]: {
                    [`&:not([disabled]) + ${componentCls}-compact-vertical-item${componentCls}-primary:not([disabled])`]: {
                        position: 'relative',
                        '&:before': {
                            position: 'absolute',
                            top: calc(token.lineWidth).mul(-1).equal(),
                            insetInlineStart: calc(token.lineWidth).mul(-1).equal(),
                            display: 'inline-block',
                            width: `calc(100% + ${unit(token.lineWidth)} * 2)`,
                            height: token.lineWidth,
                            backgroundColor: token.colorPrimaryHover,
                            content: '""',
                        },
                    },
                },
            },
        },
    };
};
// ============================== Export ==============================
export default genSubStyleComponent(['Button', 'compact'], (token) => {
    const buttonToken = prepareToken(token);
    return [
        // Space Compact
        genCompactItemStyle(buttonToken),
        genCompactItemVerticalStyle(buttonToken),
        genButtonCompactStyle(buttonToken),
    ];
}, prepareComponentToken);
